import onBreakpoint from './utils/on-breakpoint';
import getCurrentBreakpoint from './utils/get-breakpoint';
import domReady from './utils/dom-ready';
import { getBreakpoint } from './utils/getBreakpoint';

import getAdSizeMappings, { getDefaultSize } from './tv2ads/ad-size-mapping';
import { adPath } from './utils/adPath';
import creativeTemplates from './tv2ads/creative-templates';
import monitor from './tv2ads/monitor';
import tv2Ads from './tv2ads/ad-config';
import setupRefresh from './tv2ads/refresh';
import { requestBids } from './relevant';

const { utils } = window.tv2;
const log = utils.setupLog('💰 Google ads');
window.googletag = window.googletag || {};
window.dataLayer = window.dataLayer || {};
const { googletag, dataLayer } = window;

function isDeciderElem(elem) {
  try {
    if (elem.hasAttribute('data-exclude-decider')) {
      return true;
    }

    const deciderAds = ['feature-1', 'feature-2'];
    if (deciderAds.includes(elem.getAttribute('data-ad'))) {
      return true;
    }
    return false;
  } catch (_) {
    return false;
  }
}

function getFetchMargin() {
  const {
    lazyLoad: { fetchMarginPercent, mobileScaling },
  } = tv2Ads;

  let fetchMargin = fetchMarginPercent;

  /* 'mobile' vs 'desktop' */
  const getDeviceType = () => {
    return ['base', 'small'].indexOf(getBreakpoint()) >= 0
      ? 'mobile'
      : 'desktop';
  };
  const deviceType = getDeviceType();

  // need to set the fetchMargin with mobileScaling as well
  if (deviceType === 'mobile') {
    fetchMargin *= mobileScaling;
  }

  return { fetchMargin, fetchMarginPercent, mobileScaling };
}

function isInViewport(elem, offset = 200) {
  const rect = elem.getBoundingClientRect();
  const windowHeight =
    window.innerHeight || document.documentElement.clientHeight;

  // Check if the top of the element is within the viewport or above it by the offset
  const topInView = rect.top - offset <= windowHeight;

  // Check if the bottom of the element is not above the viewport
  const bottomInView = rect.bottom >= 0;

  return topInView && bottomInView;
}

function findAdsInViewPort() {
  const elements = document.querySelectorAll(
    'div[data-ad]:not([data-ad-ignore])',
  );
  const visibleElements = [];

  // Find specific elements, even if they are not in the viewport
  const specialAds = [
    'topbanner-1',
    'skyscraperleft-1',
    'skyscraperright-1',
    'topbanner-2',
    'skyscraperleft-2',
    'skyscraperright-2',
  ];

  elements.forEach((elem) => {
    const ad = elem.getAttribute('data-ad');

    const { fetchMargin } = getFetchMargin(); // Adjust this value to control how early the check fires

    // Check if element is in the viewport horizontally and vertically
    const isAdInViewport = isInViewport(elem, fetchMargin);

    // Include elements in the viewport or with special ad values
    if (isAdInViewport || specialAds.includes(ad)) {
      elem.setAttribute('data-ad-loaded', 'true');
      visibleElements.push(ad);
    }
  });

  return visibleElements;
}

function loadGoogleAds(
  advertisementId,
  consent = false,
  tracking = {},
  useProprietaryRefresh = true,
  traceIds = {},
) {
  domReady(() => {
    log.time('Time to load decider, and start loading rest of ads');
    googletag.cmd = googletag.cmd || []; // eslint-disable-line no-param-reassign

    if (tv2Ads.adsDisabled) return googletag;

    // Set allow backfill to 1
    googletag.cmd.push(() => {
      googletag.pubads().setTargeting('allowBackfill', '1');
    });

    // Find all ads using QuerySelectorAll (QSA).
    // Compatability-note: This should work in IE8+ and all other browsers,
    // and it allows us to not rely on jQuery. Not having jQuery means we
    // could place this inlined in <head> if we wanted to..
    const visibleAds = findAdsInViewPort();

    const ads = Array.from(
      document.querySelectorAll('div[data-ad]:not([data-ad-ignore])'),
    ).filter((ad) => visibleAds.includes(ad.getAttribute('data-ad')));

    if (
      !ads.length ||
      window.location.hash === '#b5efd781-75dc-44c3-bb7b-68e1263c410c'
    ) {
      return googletag; // eslint-disable-line consistent-return
    }

    if (!consent) {
      // Load up the GAM script from Google with limited ads
      googletag.cmd.push(() => {
        // Custom targeting param for use in ad manager
        googletag.pubads().setTargeting('ltd', '1');
      });
      utils.loadScript('https://pagead2.googlesyndication.com/tag/js/gpt.js');
    } else {
      // Load up the standard GAM script from Google
      utils.loadScript('https://securepubads.g.doubleclick.net/tag/js/gpt.js');
    }

    let googlePpid;
    let segments = [];
    let loggedIn;

    const { adPathPrefix, adPositionPrefix } = tv2Ads;

    // Register our ad-finding functionality to run as soon as the
    // GAM-script has loaded
    const startLoadingAds = () => {
      try {
        log.group('startLoadingAds()');
        const slots = {};
        let immediate = [];
        let excluded = { all: [] };
        const deciders = [];
        const getSlots = (paths) => paths.map((path) => slots[path]);

        // Loop through all of the ads, and register the ads with GAM
        log.group('Identifying ad slots on page');
        for (let x = 0; x < ads.length; x += 1) {
          const elem = ads[x];
          const ad = elem.getAttribute('data-ad');
          const path = `/${adPath(ad, adPathPrefix, adPositionPrefix).join(
            '/',
          )}`;
          const sizes = getAdSizeMappings(ads[x]);
          const defaultSize = getDefaultSize(sizes);
          const excludes = elem.hasAttribute('data-exclude-on')
            ? elem
                .getAttribute('data-exclude-on')
                .split(',')
                .map((s) => s.trim())
            : [];

          const isDecider = isDeciderElem(elem);

          // Assign the ad an id, so we can tie together the slot-definition
          // with the element.
          elem.id = ad;

          // Register the slot with GAM
          const currentDefineSlot = googletag.defineSlot(path, defaultSize, ad);
          let slot = null;

          // googletag.defineSlot... will return null when passing a wrong ad
          if (currentDefineSlot) {
            slot = currentDefineSlot
              .defineSizeMapping(sizes)
              .addService(googletag.pubads());
          }

          if (slot) {
            // And then also keep track of the slot-registration ourselves..
            slots[path] = slot;
            if (excludes.length === 0) {
              // The ad hasn't given us any reasons to exclude the ad, so we can load it
              immediate.push(path);
            } else {
              // We have a reason to exclude the ad, so register when to exclude.
              // We have a list of all excluded, as well as one per reason as given in the
              // data-exclude-on attribute
              excluded.all.push(path);
              for (let j = 0; j < excludes.length; j += 1) {
                if (!excluded[excludes[j]]) {
                  excluded[excludes[j]] = [];
                }
                excluded[excludes[j]].push(path);
              }
            }

            if (isDecider) {
              deciders.push(slot);
            }

            // And then we ask GAM to render it's iframe-tags inside of the
            // elements.
            // Not sure why this needs to be in a timeout, but GAM fails to
            // render correctly if it isn't.
            setTimeout(googletag.display.bind(undefined, ad), 0);
          } else {
            log.error('Unable to find slot', {
              ad,
              path,
            });
          }
        }

        log.info('Identified these ad slots on page', {
          allAdSlots: slots,
          adsToLoadImmediate: immediate,
        });
        log.groupEnd();

        try {
          if (
            useProprietaryRefresh &&
            window.location.hash !== '#667dea88ddaf2c9529494973ffaa8290'
          ) {
            setupRefresh(slots, log);
          }
        } catch (e) {
          log.warn('Setting up refresh ads failed');
        }

        // And then we enable single-requests and GAM itself, but don't do
        // an initial load.
        // Having the initial load would mean all ads load now, but we only
        // want the non-deferred ads to load now..
        googletag.pubads().enableSingleRequest();
        googletag.pubads().disableInitialLoad();

        const now = new Date();

        const addZero = (val) => (val.length < 2 ? `0${val}` : val);
        const hourOfTheDay = now.getHours().toString();
        const minutesOfTheHour = now.getMinutes().toString();
        const hm = `${addZero(hourOfTheDay)}-${addZero(minutesOfTheHour)}`;

        googletag.pubads().setTargeting('hourOfTheDay', hourOfTheDay);
        googletag.pubads().setTargeting('hm', hm);

        const { article: { tv2context = [] } = {} } = dataLayer;
        googletag
          .pubads()
          .setTargeting('tv2context', [].concat(tv2context).join(','));

        if (loggedIn) {
          googletag.pubads().setTargeting('trace-id', googlePpid);
        }

        if (segments?.length) {
          googletag.pubads().setTargeting('segm_params', segments.join(','));
        }

        const { fetchMargin } = getFetchMargin();
        googletag
          .pubads()
          .setTargeting('FetchMarginPercent', fetchMargin.toString());

        const currentBreakpoint = getCurrentBreakpoint();
        googletag.pubads().setTargeting('breakpoint', currentBreakpoint);
        // Also set breakpoint on all ad slot prev_scp's
        googletag
          .pubads()
          .getSlots()
          .forEach((slot) =>
            slot.setTargeting('breakpoint', currentBreakpoint),
          );

        if (googlePpid && tv2Ads && tv2Ads.ppid) {
          googletag.pubads().setPublisherProvidedId(googlePpid);
        }

        if (consent) {
          const t2aid = loggedIn ? advertisementId : googlePpid;
          googletag.pubads().setTargeting('t2aid', t2aid);
        }

        Object.entries(tracking).forEach(([key, val]) => {
          if (!val) {
            return;
          }
          googletag.pubads().setTargeting(key, val);
        });

        // Put page view in an A/B test bucket from 0 - 19
        const abTestBucket = Math.floor(Math.random() * 20).toString();
        googletag.pubads().setTargeting('ab', abTestBucket);

        const abTestBucketWithBreakpoint = `${abTestBucket}_${currentBreakpoint}`;
        googletag.pubads().setTargeting('ab_bp', abTestBucketWithBreakpoint);

        googletag.enableServices();

        let midScrollParamIsSet = false;
        const setMidscrollParam = (isAllowed) => {
          if (midScrollParamIsSet) return;
          // Do never allow mid scroll on articles with top video
          const isVideoArticle =
            tracking.article && tracking.article.videoArticle;
          const allowMidscroll = isVideoArticle ? false : isAllowed;
          log.info(`${allowMidscroll ? 'Enabling' : 'Disabling'} mid scroll`);
          googletag
            .pubads()
            .setTargeting('allowMidscroll', allowMidscroll ? '1' : '0');
          midScrollParamIsSet = true;
        };

        if (deciders.length && excluded.all.length > 0) {
          // By default we assume that we need to load all the excluded
          // ads when we have decided.
          // This list will be modified if we have a creative-type that
          // excludes some of the ads.
          let adsToLoadWhenDecided = excluded.all;

          // Helper function - can subtract a list from another (so array_diff equiv)
          const subtractList = (list, subtract) => {
            if (!subtract || !subtract.indexOf) return list;
            return list.filter((item) => subtract.indexOf(item) === -1);
          };

          // We have a helper-message that gets called whenever we
          // receive a post-message that was valid JSON, and contains a
          // type-property
          const creativeTemplateElem = creativeTemplates.create();
          const handleMessage = (message) => {
            log.timeEnd('Time to load decider, and start loading rest of ads');
            log.info(`Ad is of type ${message.type}`);
            let adsToExclude;
            switch (message.type) {
              case 'TV2_DFP_ADFORM':
                // No-op for now
                break;
              case 'TV2_ADQ_DOMINANS':
              case 'TV2_ADQ_DD':
                creativeTemplates.dd(message, creativeTemplateElem);
                // Do not allow mid scroll ads with DD
                setMidscrollParam(false);
                adsToExclude = excluded.dd;
                break;
              case 'TV2_ADQ_TAKEOVER':
              case 'TV2_ADQ_INTERSCROLL':
                creativeTemplates.interscroll(message, creativeTemplateElem);
                // Do not allow mid scroll ads with interscroll
                setMidscrollParam(false);
                adsToExclude = excluded.interscroll;
                break;
              case 'TV2_ADQ_MIDSCROLL':
                creativeTemplates.midscroll(message, creativeTemplateElem);
                break;
              default:
                break;
            }
            log.info(`${message.type} ad excludes these ads`, {
              adsToExclude,
            });
            adsToLoadWhenDecided = subtractList(
              adsToLoadWhenDecided,
              adsToExclude,
            );
          };

          // We have ad-units that marked themselves as deciders, and we
          // have excluded ads.
          let hasDecided = false;

          googletag.pubads().addEventListener('slotRenderEnded', (event) => {
            const handleCreative = (template, slot) => {
              try {
                const conf = eval(template[1]); // eslint-disable-line no-eval
                handleMessage({
                  ...conf[1],
                  type: `TV2_ADQ_${conf[0]}`,
                  elementId: slot.getSlotElementId(),
                });
              } catch (e) {
                log.error('slotRenderEnded event handler failed on ad', e);
              }
            };

            const hasAd = !!event.slot.getResponseInformation();
            const html = event.slot.getHtml();
            const matches = html.match(/TV2ADQ\.push\((.+)\);\n<\/script>/s);

            if (deciders.indexOf(event.slot) !== -1) {
              log.info(
                'Decider ad triggered slotRenderEnded - attempting to pull out the ad type from the ad HTML',
              );

              if (matches && matches[1]) {
                handleCreative(matches, event.slot);
                hasDecided = true;
              }

              if (hasDecided || !hasAd) {
                log.info('Starting to load rest of the ads', {
                  adsToLoadWhenDecided,
                });

                // Allow mid scroll if not already disabled
                setMidscrollParam(true);
                googletag.pubads().refresh(getSlots(adsToLoadWhenDecided), {
                  changeCorrelator: false,
                });
              }
            } else if (matches && matches[1]) {
              // MIDSCROLL AD is not a decider ad so we need to handle it here
              handleCreative(matches, event.slot);
            }
          });

          window.addEventListener('message', (event) => {
            // Parse post message data. We support an object and stringified JSON
            let message;
            if (event.data instanceof Object && event.data.type) {
              message = event.data;
            } else if (
              typeof event.data === 'string' &&
              event.data[0] === '{'
            ) {
              try {
                const data = JSON.parse(event.data);
                message = data;
              } catch (error) {
                log.error(
                  'Failed to parse event data or execute "handleMessage" from "message" event',
                  { error, event },
                );
              }
            }

            // Determine message type, used by all post messages that we want to react to
            const { type = '' } = message || {};

            // We are using Adnami to load DD banners through programmatic.
            // We will listen for Adnami unloading macro post message, which will require us to
            // disable skyscrapers on the page, otherwise they will display on top of the
            // Adnami creative.
            if (type === 'ADSM_MACRO_UNLOAD') {
              setMidscrollParam(false);
              const adsToExclude = excluded.dd;
              adsToLoadWhenDecided = subtractList(
                adsToLoadWhenDecided,
                adsToExclude,
              );
            }

            // If the above experimental function does not manage to pull out the Ad Type,
            // this one will listen to the postMessage call from tv2adq.js
            // So we bind a event-listener for post-messages.
            if (hasDecided) return;

            if (type.includes('TV2_ADQ')) {
              log.info('Received postMessage from tv2adq.js', { event });
              handleMessage(message);
            }
          });

          googletag.pubads().addEventListener('slotRequested', (event) => {
            log.info(event.slot.getSlotElementId(), 'fetched');
          });

          // Bind an event-listener for when the decider ad-unit have finished loaded
          googletag.pubads().addEventListener('slotOnload', (event) => {
            log.info(event.slot.getSlotElementId(), 'rendered');
            if (deciders.indexOf(event.slot) !== -1 && !hasDecided) {
              log.info(
                "Decider ad loaded, and it wasn't a special type - starting to load rest of the ads",
                { adsToLoadWhenDecided },
              );
              setTimeout(() => {
                // Allow mid scroll if not already disabled
                setMidscrollParam(true);
                googletag.pubads().refresh(getSlots(adsToLoadWhenDecided), {
                  changeCorrelator: false,
                });
              }, 15);
            }
          });

          // When we change breakpoints, we need to start the process over from stratch
          onBreakpoint(() => {
            log.info('Breakpoint changed - loading new ads');
            midScrollParamIsSet = false;
            // First we clear out the displayed ad from all of the potentially excluded ads
            googletag.pubads().clear(getSlots(excluded.all));

            // Then we reset the list of possible ad-units to load
            adsToLoadWhenDecided = excluded.all;

            // And then the post-messages and slotOnload-events should trigger again
            // once the decider has refreshed
            hasDecided = false;
          }, true);
        } else if (!deciders.length && excluded.all.length > 0) {
          // We don't have deciders, but we have excluded ads. That's probably a
          // misconfigured site - so we unexclude them by adding them to the immediate
          // list and resetting the excluded object.
          immediate = immediate.concat(excluded.all);
          excluded = { all: [] };
        }

        // Set non-personalised ads and Refresh ad slots
        const refreshAds = (adSlots, options) => {
          midScrollParamIsSet = false;
          const targetSlots = getSlots(adSlots);
          googletag.pubads().refresh(targetSlots, options);
        };

        // If the pbjs sdk is loaded, perform a data sync
        // http://prebid.org/dev-docs/publisher-api-reference.html#module_pbjs.setTargetingForGPTAsync
        // And then we ask all of the non-deferred ads to load...
        log.group(
          'Loading of immediate ads (those that are not exluded by any type of decider)',
        );

        window.tv2messages.onceWithTimeout('pbjsDone', 3500, (pbjs) => {
          if (pbjs) {
            try {
              window.pbjs.handleSync();
            } catch (err) {
              log.error('Unable to syncronize with prebid', err);
            }
          } else {
            log.warn('prebid not resolved in time');
          }
          log.info('Start loading immediate ads', {
            immediateAds: immediate,
          });
          refreshAds(immediate);
          log.groupEnd(); // End 'Loading of immediate ads'
          log.groupEnd(); // End 'startLoadingAds'
          log.groupEnd(); // End 'Initialization'
        });

        // And register them so they refresh on reloads
        onBreakpoint(() => {
          refreshAds(immediate, { changeCorrelator: true });
        }, true);
      } catch (e) {
        log.error('startLoadingAds failed to execute', e);
      }
    };

    const adsShouldHavePpid = tv2Ads && tv2Ads.ppid;

    googletag.cmd.push(() => {
      log.group('Initialization');
      log.group('Getting login status');
      const silentAuth = utils.getCookie('silent_auth');
      if (!adsShouldHavePpid) {
        startLoadingAds();
        log.info('adsShouldHavePpid: false');
      } else {
        const ppid = traceIds && traceIds['google-ppid'];
        segments = (traceIds && traceIds['audience-supplier-segments']) || [];
        log.info(`${ppid ? 'Logged in!' : 'Not logged in'}`, {
          traceIds,
          ppid,
        });
        log.groupEnd();
        if (ppid && silentAuth === '1') {
          googlePpid = ppid;
          loggedIn = true;
        } else {
          googlePpid = advertisementId;
          loggedIn = false;
        }
        startLoadingAds();
      }

      /**
       * This section is for lazy loading ads
       * 1. Configuration for page types and device types
       * 2. Intersection observer for ads in viewport
       * 3. Mutation observer for new ad placements
       */

      /**
       * Ad Tech team needs to be able to control the fetchMargin depending on the page type
       * and device type. This is to ensure that ads are fetched at the right time.
       */
      // eslint-disable-next-line camelcase

      const { fetchMargin, fetchMarginPercent, mobileScaling } =
        getFetchMargin();

      log.info(`Lazy load configuration`, {
        fetchMargin,
        fetchMarginPercent,
        mobileScaling,
      });

      /**
       * Intersect ads in viewport and load them
       */
      function handleAdIntersection(entries) {
        const newSlotsRegistered = [];
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const elem = entry.target;
            const ad = elem.getAttribute('data-ad');
            /**
             * This check is to prevent multiple ad loads on the same element
             * Prevents Interscroll ads to get overriden by a Google ad
             */
            if (elem.children.length > 0) {
              log.info('Ad slot has child element', elem.children[0]);
              return;
            }

            // check if ad is already loaded, if not, create ad slot
            if (!visibleAds.includes(ad)) {
              log.info('Creating ad slot on element', ad);

              const path = `/${adPath(ad, adPathPrefix, adPositionPrefix).join(
                '/',
              )}`;
              const sizes = getAdSizeMappings(elem);
              const defaultSize = getDefaultSize(sizes);
              const slot = googletag
                .defineSlot(path, defaultSize, ad)
                .defineSizeMapping(sizes)
                .addService(googletag.pubads());

              // This will only register the slot.
              // Ad will be fetched only when refresh is called.
              googletag.display(slot);

              // Keep track of new slots registered so we can refresh them
              newSlotsRegistered.push(slot);
              visibleAds.push(ad);
              elem.setAttribute('data-ad-loaded', 'true');
            }

            // Refresh new slots so they can fetch ads and render
            if (newSlotsRegistered.length > 0) {
              log.info('Refreshing new ad slots', { newSlotsRegistered });
              // Update relevant prebid bids for new slots
              requestBids({ tracking, advertisementId });
              googletag.pubads().refresh(newSlotsRegistered);
            }

            log.groupEnd();
          }
        });
      }

      // Create a new IntersectionObserver instance
      const initializeIntersectionObserver = new IntersectionObserver(
        handleAdIntersection,
        {
          rootMargin: `${fetchMargin}px 0px ${fetchMargin}px 0px`,
          threshold: 0,
        },
      );

      // Select all elements with the same class
      const targetElements = document.querySelectorAll(
        'div[data-ad]:not([data-ad-ignore])[data-ad-loaded="false"]',
      );

      // Loop through each element and observe them
      targetElements.forEach((element) => {
        initializeIntersectionObserver.observe(element);
      });

      /**
       * Check for new ad placements on the page and load new ads
       */
      const findAndLoadNewAdSlots = () => {
        // Find all new empty ad elements with data-ad-loaded attribute is set to false
        const newSlots = document.querySelectorAll(
          'div[data-ad]:not([data-ad-ignore])[data-ad-loaded="false"]',
        );

        googletag.cmd.push(() => {
          const newSlotsRegistered = [];

          const newSlotIds = [...newSlots].map((adNode) =>
            adNode.getAttribute('data-ad'),
          );

          // Loop through all ad elements and register slots for new ones
          newSlots.forEach((adNode) => {
            const elem = adNode;
            const ad = elem.getAttribute('data-ad');
            elem.id = ad;

            // Check if element is in the viewport horizontally and vertically
            setTimeout(() => {
              const isAdInViewport = isInViewport(elem, fetchMargin);

              if (isAdInViewport) {
                /**
                 * This check is to prevent multiple ad loads on the same element
                 * Prevents Interscroll ads to get overriden by a Google ad
                 */
                if (elem.children.length > 0) {
                  log.info('Ad slot has child element', elem.children[0]);
                  return;
                }

                // check if ad is already loaded, if not, create ad slot
                if (newSlotIds.includes(ad)) {
                  log.info('New ad slot found', { ad });

                  const sizes = getAdSizeMappings(adNode);
                  const defaultSize = getDefaultSize(sizes);
                  const path = `/${adPath(
                    ad,
                    adPathPrefix,
                    adPositionPrefix,
                  ).join('/')}`;

                  let slot;
                  try {
                    slot = googletag.defineSlot(path, defaultSize, ad);
                    if (slot) {
                      slot
                        .defineSizeMapping(sizes)
                        .addService(googletag.pubads());

                      // This will only register the slot.
                      // Ad will be fetched only when refresh is called.
                      googletag.display(slot);

                      // Keep track of new slots registered so we can refresh them
                      newSlotsRegistered.push(slot);

                      // Ensure the ad is marked as loaded to prevent multiple loads
                      elem.setAttribute('data-ad-loaded', 'true');
                    } else {
                      log.warn(`Failed to define slot for ad: ${ad}`);
                    }
                  } catch (error) {
                    log.error(`Error defining slot for ad: ${ad}`, error);
                  }
                }
                // Refresh new slots so they can fetch ads and render
                if (newSlotsRegistered.length > 0) {
                  log.info('Refreshing new ad slots', { newSlotsRegistered });
                  // Update relevant prebid bids for new slots
                  requestBids({ tracking, advertisementId });
                  googletag.pubads().refresh(newSlotsRegistered);
                }
              }
            }, 0);
          });
          log.groupEnd();
        });
      };

      // Function to start the observer
      const observer = new MutationObserver(findAndLoadNewAdSlots);
      observer.observe(document, {
        childList: true,
        subtree: true,
      });
    });

    // Add monitor script
    monitor(googletag);

    return googletag;
  });
}

export { loadGoogleAds };
